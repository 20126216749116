import { React } from "react";
import { Container } from "react-bootstrap";
import CardHSPUProgram from "./CardHSPUProgram";
import "../css/Home.css";
import "../css/Program.css";
import "../css/ComingSoon.css";
import { Helmet } from "react-helmet";

function Program() {
  const video_row_2 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/video_run.mp4";

  return (
    <div className="page">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>
      <Container fluid>
        <div className="home-row row-program">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/program">
              Program
            </a>
          </h1>
          <h2 className="home-row-subtitle">
            Click on progression to see videos!
          </h2>
          <CardHSPUProgram />
        </div>
      </Container>
    </div>
  );
}
export default Program;
