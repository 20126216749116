import React from "react";
import logo_text from "../assets/img/t4a_logo_text_white.svg";

function DisclaimerContent() {
  return (
    <div>
      <h1 className="title">User Content Disclaimer</h1>
      <p>
        By using our service and uploading any content, you agree that <strong><img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />(Thenics4All)</strong>  can store, upload, and share any videos or content you share through the app. This includes but is not limited to the use of your content for promotional and marketing purposes.
      </p>
      <p>
        You retain all ownership rights to your content, but you grant us a worldwide, non-exclusive, royalty-free license to use, distribute, reproduce, and display the content in connection with the service and <strong><img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />(Thenics4All)</strong>  business.
      </p>
      <p>
        You acknowledge that you have all necessary rights and permissions to grant us this license for any content you upload.
      </p>
      <p>
        If you have any questions about this disclaimer, please contact us at thenics4all@gmail.com.
      </p>
      <p>
        <strong>
          By uploading content, you agree to these terms and conditions.
        </strong>
      </p>
    </div>
  );
}

export default DisclaimerContent;
