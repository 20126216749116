import { createStore } from 'redux';

// Define the initial state
const initialState = {
  isAuthenticated: false,
};

// Define a reducer function to update the state
function reducer(state = initialState, action) {
  switch (action.type) {
    case 'setAuthentication':
      return { ...state, isAuthenticated: action.payload };
    default:
      return state;
  }
}

// Action creator function to update the user authentication state
export function setAuthentication(isAuthenticated) {
  return { type: 'setAuthentication', payload: isAuthenticated };
}

// Create the Redux store
const store = createStore(reducer);

export default store;
