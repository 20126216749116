  // Define user data with progress values
  const data_user = [
    {
      name: "User",
      progressData: [
        { progress: 12, progress_code: "l1a" },
        { progress: 35, progress_code: "l1b" },
        { progress: 11, progress_code: "l1c" },
        { progress: 6, progress_code: "l2a" },
        { progress: 55, progress_code: "l2b" },
        { progress: 9, progress_code: "l2c" },
        { progress: 3, progress_code: "l3a" },
        { progress: 15, progress_code: "l3b" },
        { progress: 2, progress_code: "l3c" },
        { progress: 3, progress_code: "l4a" },
        { progress: 5, progress_code: "l4b" },
        { progress: 1, progress_code: "l4c" },
        { progress: 0, progress_code: "l5a" },
        { progress: 0, progress_code: "l5b" },
        { progress: 0, progress_code: "l5c" },
        { progress: 0, progress_code: "l6a" },
        { progress: 0, progress_code: "l6b" },
        { progress: 0, progress_code: "l6c" },
        { progress: 0, progress_code: "hspu" },
      ],
    },
    {
        name: "Zarrar",
        progressData: [
          { progress: 25, progress_code: "l1a" },
          { progress: 60, progress_code: "l1b" },
          { progress: 15, progress_code: "l1c" },
          { progress: 12, progress_code: "l2a" },
          { progress: 60, progress_code: "l2b" },
          { progress: 10, progress_code: "l2c" },
          { progress: 10, progress_code: "l3a" },
          { progress: 60, progress_code: "l3b" },
          { progress: 10, progress_code: "l3c" },
          { progress: 0, progress_code: "l4a" },
          { progress: 0, progress_code: "l4b" },
          { progress: 0, progress_code: "l4c" },
          { progress: 0, progress_code: "l5a" },
          { progress: 0, progress_code: "l5b" },
          { progress: 0, progress_code: "l5c" },
          { progress: 0, progress_code: "l6a" },
          { progress: 0, progress_code: "l6b" },
          { progress: 0, progress_code: "l6c" },
          { progress: 0, progress_code: "hspu" },
        ],
      },
    {
        name: "PashaMan",
        progressData: [
          { progress: 25, progress_code: "l1a" },
          { progress: 60, progress_code: "l1b" },
          { progress: 1, progress_code: "l1c" },
          { progress: 10, progress_code: "l2a" },
          { progress: 75, progress_code: "l2b" },
          { progress: 2, progress_code: "l2c" },
          { progress: 0, progress_code: "l3a" },
          { progress: 0, progress_code: "l3b" },
          { progress: 0, progress_code: "l3c" },
          { progress: 0, progress_code: "l4a" },
          { progress: 0, progress_code: "l4b" },
          { progress: 0, progress_code: "l4c" },
          { progress: 0, progress_code: "l5a" },
          { progress: 0, progress_code: "l5b" },
          { progress: 0, progress_code: "l5c" },
          { progress: 0, progress_code: "l6a" },
          { progress: 0, progress_code: "l6b" },
          { progress: 0, progress_code: "l6c" },
          { progress: 0, progress_code: "hspu" },
        ],
      },
      {
        name: "Akbar",
        progressData: [
          { progress: 30, progress_code: "l1a" },
          { progress: 0, progress_code: "l1b" },
          { progress: 14, progress_code: "l1c" },
          { progress: 8, progress_code: "l2a" },
          { progress: 0, progress_code: "l2b" },
          { progress: 0, progress_code: "l2c" },
          { progress: 0, progress_code: "l3a" },
          { progress: 0, progress_code: "l3b" },
          { progress: 0, progress_code: "l3c" },
          { progress: 0, progress_code: "l4a" },
          { progress: 0, progress_code: "l4b" },
          { progress: 0, progress_code: "l4c" },
          { progress: 0, progress_code: "l5a" },
          { progress: 0, progress_code: "l5b" },
          { progress: 0, progress_code: "l5c" },
          { progress: 0, progress_code: "l6a" },
          { progress: 0, progress_code: "l6b" },
          { progress: 0, progress_code: "l6c" },
          { progress: 0, progress_code: "hspu" },
        ],
      },
  ];

  export default data_user;