import React from "react";
import logo_text from "../assets/img/t4a_logo_text_white.svg";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { BsTiktok } from "react-icons/bs";
import "../css/Footer.css";

function Footer() {
  return (
    <footer className="footer">
      <div>
        <p className="footer-message">
          &copy; {new Date().getFullYear()} <img className="logo-text-footer" src={logo_text} alt="Logo_text" />.  
          <a href="/disclaimer" rel="noopener noreferrer">Disclaimer </a>
        </p>
      </div>
      <div className="social-media-icons">
        <a href="/" target="_blank" rel="noopener noreferrer">
        <AiFillInstagram className="social-media-icon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
        <AiFillFacebook className="social-media-icon" /> 
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <AiFillYoutube className="social-media-icon" />
        </a>
        <a href="/" target="_blank" rel="noopener noreferrer">
          <BsTiktok className="social-media-icon" />
        </a>
      </div>
    </footer>
  );
}

export default Footer;
