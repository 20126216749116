import { React } from "react";
import { Container } from "react-bootstrap";
import CardHSPUTrain from "./CardHSPUTrain";
import "../css/Home.css";
import "../css/Train.css";
import "../css/ComingSoon.css";
import { Helmet } from "react-helmet";

function Train() {
  const video_row_3 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/video_pu.mp4";

  return (
    <div className="page">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>
      <Container fluid>
        <div className="home-row row-train">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/train">
              Train
            </a>
          </h1>
          <h2 className="home-row-subtitle">Training videos for each level</h2>
          <CardHSPUTrain />
        </div>
      </Container>
    </div>
  );
}
export default Train;
