import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import logo from "../assets/img/t4a_logo_white.svg";
import { FaUsers } from "react-icons/fa";
import { AiFillHome } from "react-icons/ai";
import icon_events from "../assets/img/icon_events.svg";
import icon_hspu from "../assets/img/icon_hspu.svg";
import { FaDumbbell } from "react-icons/fa";
import { BiSolidUser } from "react-icons/bi";
import "../css/Navigation.css";

function Navigation() {
  const [expanded, setExpanded] = useState(false);

  const handleToggle = () => {
    setExpanded(!expanded);
  };

  const handleClose = () => {
    setExpanded(false);
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      className="bartop"
      expanded={expanded}
    >
      <Container fluid>
        <Navbar.Brand href="/">
          <img className="logo" src={logo} alt="Logo" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="navbar-nav" onClick={handleToggle} />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="ms-auto nav-right">
            <NavLink className="nav-link" to="/" onClick={handleClose}>
              <div className="nav-icon">
                <AiFillHome />
              </div>
              Home
            </NavLink>
            <NavLink className="nav-link" to="/program" onClick={handleClose}>
              <div className="nav-icon">
                <img
                  className="nav-icon-custom-hspu"
                  src={icon_hspu}
                  alt="icon_hspu"
                />
              </div>
              Program
            </NavLink>
            <NavLink className="nav-link" to="/train" onClick={handleClose}>
              <div className="nav-icon">
                <FaDumbbell />
              </div>
              Train
            </NavLink>
            <NavLink className="nav-link" to="/progress" onClick={handleClose}>
              <div className="nav-icon">
                <img
                  className="nav-icon-custom-events"
                  src={icon_events}
                  alt="icon_event"
                />
              </div>
              Progress
            </NavLink>
            <NavLink className="nav-link" to="/about" onClick={handleClose}>
              <div className="nav-icon">
                <FaUsers />
              </div>
              About
            </NavLink>
            <NavLink className="nav-link" to="/login" onClick={handleClose}>
              <div className="nav-icon">
                <BiSolidUser />
              </div>
              Account
            </NavLink>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default Navigation;
