import React, { createContext, useContext, useState } from 'react';

const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState({
    handle: '',
    email: '',
    avatar: '',
    youtube: '',
    instagram: '',
    tiktok: '',
    twitter: '',
    disclaimerAccepted: false,
    isFirstSignIn: true,
  });

  const signIn = () => {
    setIsAuthenticated(true);
    setUser((prevUser) => ({
      ...prevUser,
      handle: 'my_handle',
      email: 'my_email@email.com',
      isFirstSignIn: true,
    }));
  };

  const completeSetup = () => {
    setUser((prevUser) => ({
      ...prevUser,
      isFirstSignIn: false,
    }));
  };

  const signOut = () => {
    setIsAuthenticated(false);
    setUser({
      handle: '',
      email: '',
      avatar: '',
      youtube: '',
      instagram: '',
      tiktok: '',
      twitter: '',
      disclaimerAccepted: false,
      isFirstSignIn: true,
    });
  };

  const updateUser = async (updatedUser) => {
    // Mock delay to simulate network request
    await new Promise((resolve) => setTimeout(resolve, 1000));
    setUser(updatedUser);
  };

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        signIn,
        completeSetup,
        signOut,
        updateUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
