import { React } from "react";
import logo_text from "../assets/img/t4a_logo_text_white.svg";

function DisclaimerExercise() {
  return (
<div>
<h1 className="title">Fitness and Exercise Disclaimer</h1>

    <p><strong>Please review the following carefully:</strong></p>

    <p>The purpose of this <strong>DISCLAIMER AND NOTICE</strong> is to make you conscious of the potential risks connected with activity in any exercise, physical fitness, or training program. And to help you make an informed decision about whether you should participate in these activities.</p>

    <p>You should understand that participating in any exercise or exercise program carries the possibility of physical injury. You should be in good physical condition and able to participate in the exercise. If you engage in this exercise or exercise program, you agree that you do so at your own risk, are voluntarily participating in these activities, and assume all risk of injury to yourself.</p>

    <p>Injuries of all types can occur when participating in exercise, physical fitness, and training programs, hence <strong><img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />(Thenics4All)</strong> strongly encourages all members to obtain a comprehensive physical examination by a licensed physician <strong>PRIOR</strong> to undertaking any exercise or training demonstrated on this website, and/or any videos, MP4s, MP3s, and training manuals offered on this website. Such injuries include but are not limited to:</p>

    <ul>
        <li>Bruising, cuts, and general soreness</li>
        <li>Muscular and tendon injuries</li>
        <li>Ligament and skeletal injuries</li>
        <li>Fractured or broken bones</li>
        <li>Concussion</li>
        <li>Heart attack</li>
    </ul>

    <p><strong><img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />(Thenics4All) EXPRESSLY DISCLAIMS LIABILITY FOR ALL DAMAGES AND ASSUMES NO LIABILITY OR RESPONSIBILITY FOR ANY LOSS, INJURY, OR DAMAGE SUFFERED BY ANY PERSON AS A RESULT OF THE USE, MISUSE, REFERENCE TO, RELIANCE ON, OR RESULTS OBTAINED FROM ANY INFORMATION, VIDEOS, AUDIO OR TRAINING MANUALS MADE AVAILABLE ON THIS WEBSITE OR FROM INFORMATION OBTAINED AT LINKED SITES OR RECOMMENDED PRODUCTS.</strong></p>

    <p>By using any of the content on this website you hereby agree to release, discharge, indemnify, and hold harmless <strong><img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />(Thenics4All)</strong> and its officers, directors, shareholders, members, employees, agents, and their respective successors and assignees against any loss, liability, damage, claim, cause of action, known or unknown cost, or expense of any nature whatsoever, including without limitation reasonable attorneys’ fees and other legal costs arising from the content of this website.</p>

    <p><strong>This content, information, videos, MP4s, MP3s, and training manuals offered and made available on this website are for informational purposes only.</strong></p>

    <p><strong>USE THE INFORMATION ON THIS WEBPAGE & APP AT YOUR OWN RISK</strong></p>
</div>
  );
}
export default DisclaimerExercise;
