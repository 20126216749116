import React from "react";
import { Container } from "react-bootstrap";
import logo from "../assets/img/t4a_logo_white.svg";
import logo_text from "../assets/img/t4a_logo_text_white.svg";
import "../css/Home.css";
import "../css/react-image-gallery.css";
import CardHSPUProgram from "./CardHSPUProgram";
import CardHSPUTrain from "./CardHSPUTrain";
import CardHSPUProgress from "./CardHSPUProgress";
import data_user from '../data/data_user';
import img_team from "../assets/img/team.png";
import { AiFillInstagram } from "react-icons/ai";
import { AiFillFacebook } from "react-icons/ai";
import { AiFillYoutube } from "react-icons/ai";
import { BsTiktok } from "react-icons/bs";
import { Helmet } from "react-helmet";

function Home() {
  const video_row_1 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/video_handstandpu.mp4";
  const video_row_2 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/video_run.mp4";
  const video_row_3 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/video_pu.mp4";
  const video_row_4 =
    "https://t4abucket225336-staging.s3.us-east-2.amazonaws.com/videos_rope.mp4";


  return (
    <div className="page home">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>

      <Container fluid>
        {/************** Row 1 ****************/}

        <div className="home-row row-1">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_1} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <img className="home-logo-text" src={logo_text} alt="thenics4all" />
          <img className="home-logo" src={logo} alt="Logo" />
          <h2 className="home-row-subtitle row-1">Unlock Impossible!</h2>
        </div>
        <hr className="home-horizontal-rule" />

        {/************** Row  2 ****************/}

        <div className="home-row row-program">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_2} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/program">
              Program
            </a>
          </h1>
          <h2 className="home-row-subtitle">
            Click on progression to see videos!
          </h2>
          <CardHSPUProgram />
        </div>
        <hr className="home-horizontal-rule" />

        {/************** Row  3 Train ****************/}

        <div className="home-row row-train">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_3} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/train">
              Train
            </a>
          </h1>
          <h2 className="home-row-subtitle">Training videos for each level</h2>
          <CardHSPUTrain />
        </div>
        <hr className="home-horizontal-rule" />

        {/************** Row  4 Progress ****************/}

        <div className="home-row">
          <video
            autoPlay
            loop
            muted
            playsInline
            className="home-row-video-background"
          >
            <source src={video_row_4} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <h1>
            <a className="home-link" href="/progress">
              Track Progress
            </a>
          </h1>
          <h2 className="home-row-subtitle">
          <a className="home-link-join" href="/login">Join</a> to track progress</h2>
          <CardHSPUProgress data_user={data_user} />
        </div>
        <hr className="home-horizontal-rule" />

        {/************** Row 4****************/}

        <div className="home-row row-long">
          <h1>
            <a className="home-link" href="/about">
              About
            </a>
          </h1>
          <h2 className="home-row-subtitle">Share, inspire & be inspired</h2>
          <div>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <AiFillInstagram className="social-media-icon-home" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <AiFillFacebook className="social-media-icon-home" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <AiFillYoutube className="social-media-icon-home" />
            </a>
            <a href="/" target="_blank" rel="noopener noreferrer">
              <BsTiktok className="social-media-icon-home" />
            </a>
          </div>
          <div className="home-about">
            I began
            <img
              className="home-logo-text-inline"
              src={logo_text}
              alt="thenics4all"
            />
            to document my journey in unlocking what once seemed to me an
            unattainable skill. I hope this becomes a source of inspiration &
            motivation to believe in your own potential. I invite you to join
            me, start your own journey, and prove to yourself that the seemingly
            impossible is within reach!
            <div className="home-about-sign">- Zarrar Khan </div>
            <div className="home-about-image">
              <div className="home-about-image-text">
                My team. My inspiration.
              </div>
              <div className="pashaman">@PashaMan</div>
              <div className="akbar">@Akbar</div>
              <div className="zarrar">@Zarrar</div>
              <img src={img_team} alt="Message" />
            </div>
          </div>
          <div></div>
        </div>

        {/************** End Row  ****************/}

        <div className="home-bottom">
          <hr className="home-horizontal-rule" />
        </div>
      </Container>
    </div>
  );
}

export default Home;
