import React, { useRef, useState, useEffect } from "react";
import styles from "../css/CardHSPUTrain.module.css";
import headerImage from "../assets/img/icon_hspu_white.png";
import html2canvas from "html2canvas";
import { FaDownload } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";
import logo from "../assets/img/t4a_logo_white.svg";

function CardHSPUTrain() {
  const cardRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");

  const handleCapture = () => {
    if (cardRef.current) {
      html2canvas(cardRef.current, {
        scale: 4,
        backgroundColor: "transparent", // Set the background to transparent
      }).then((canvas) => {
        // Convert the canvas to a data URL and create a download link
        const dataURL = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        a.href = dataURL;
        a.download = "thenics4all_hspu_training.png";
        a.click();
      });
    }
  };

  // Add an event listener to close the modal when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showVideo &&
        cardRef.current &&
        !cardRef.current.contains(event.target)
      ) {
        closeVideo();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showVideo]);

  const communityData = [
    {
      level: "Level 1",
      color: "white",
      text1: "Level 1 |  | Watch Video",
      text4: "1 x 5 Wall Pushups | 1 x 5 Hang | 1 x 20s Table",
      text3: "2 x 5 Knee Pushups | 2 x 5 Neg Dip | 2 x 20s Knee Plank",
      text2: "3 x 5 Pushups | 3 x 5 Dips | 3 x 20s Planks",
      videoUrl: "https://www.youtube.com/embed/dvfL1tywENc",
    },
    {
      level: "Level 2",
      color: "rgb(155, 134, 17)",
      text1: "Level 2 |  | Watch Video",
      text4: "1 x 5 Knee Pushups | 1 x 5 Neg Dip | 1 x 20s Knee Plank",
      text3: "2 x 5 Pushups | 2 x 5 Dips | 2 x 20s Planks",
      text2: "3 x 5 Pike Pushup | 3 x 5 Tripod Raise | 3 x 20s Wall HS",
      videoUrl: "https://www.youtube.com/embed/imjRptwHP-k",
    },
    {
      level: "Level 3",
      color: "rgb(16, 16, 89)",
      text1: "Level 3 |  | Watch Video",
      text4: "1 x 5 Pushups | 1 x 5 Dips | 1 x 20s Plank",
      text3: "2 x 5 Pike Pushup | 2 x 5 Tripod Raise | 2 x 20s Wall HS",
      text2: "3 x 5 Pike PU to Tripod | 3 x 5 Tripod to HS Kip | 3 x 20s Frogstand",
      videoUrl: "https://www.youtube.com/embed/ZpzTssVUE7U",
    },
    {
      level: "Level 4",
      color: "rgb(24, 62, 24)",
      text1: "Level 4 |  | Watch Video",
      text4: "1 x 5 Pike PU | 1 x 5 Tripod Raise | 1 x 20s Wall HS",
      text3: "2 x 5 Pike PU to Tripod | 2 x 5 Tripod to HS Kip | 2 x 20s Frogstand",
      text2: "3 x 5 Face Wall HSPU | 3 x 6 1-Leg Crows | 3 x 20s Free HS",
      videoUrl: "https://www.youtube.com/embed/dvfL1tywENc",
    },
    {
      level: "Level 5",
      color: "rgb(68, 13, 13)",
      text1: "Level 5 |  | Watch Video",
      text4: "1 x 5 Pike PU to Tripod | 1 x 5 Tripod to HS Kip | 1 x 20s Frogstand",
      text3: "2 x 5 Face Wall HSPU | 2 x 6 1-Leg Crows | 2 x 20s Free HS",
      text2: "3 x 10 Face Wall HSPU | 3 x 5 2-Leg Crows | 3 x 5 HS to Tripod",
      videoUrl: "https://www.youtube.com/embed/imjRptwHP-k",
    },
    {
      level: "Level 6",
      color: "#1f1f1f",
      text1: "Level 6 |  | Watch Video",
      text4: "1 x 5 Face Wall HSPU | 1 x 6 1-Leg Crows | 1 x 20s Free HS",
      text3: "2 x 10 Face Wall HSPU | 2 x 5 2-Leg Crows | 2 x 5 HS to Tripod",
      text2: "3 x 15 Face Wall HSPU | 3 x 5 HS Crow HS | 3 x 5 HS Tripod Kip HS",
      videoUrl: "https://www.youtube.com/embed/ZpzTssVUE7U",
    },
  ];

  const openVideo = (url) => {
    console.log("Opening video:", url); // Add this line for debugging
    setVideoUrl(url);
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  return (
    <div className={styles.card} ref={cardRef}>
      <div className={styles.cardHeader}>
        <img src={headerImage} alt="Header" className={styles.headerImage} />
        <div>
          <span>Handstand Pushup</span>
          <p className={styles.subtitle}>Training Plan</p>
          <p className={styles.subtitle2}> 3 Times a Week (To max reps)</p>
        </div>
        <div className={styles.downloadButton} onClick={handleCapture}>
          <FaDownload />
        </div>
      </div>
      <table className={styles.cardTable}>
        <tbody>
          {communityData.map((data, index) => (
            <tr
              key={index}
              className={`${styles.level}-${index + 1}`}
              onClick={() => openVideo(data.videoUrl)}
            >
              <td
                className={styles.progressionCell}
                style={{
                  backgroundColor: data.color,
                  color: data.color === "white" ? "black" : "white",
                }}
              >
                <div className={styles.textSection}>
                  {data.text1.split(" | ").map((section, index) => (
                    <div
                      key={index}
                      className={`${index === 0 ? styles.level1Text : null} ${
                        index === 0
                          ? styles.text_subsection_1 // Apply text1-subsection-1 class here
                          : index === 1
                          ? styles.text_subsection_2 // Apply text1-subsection-2 class here
                          : index === 2
                          ? styles.text_subsection_3 // Apply text1-subsection-3 class here
                          : null // Add more conditions if needed
                      }`}
                    >
                      {index === 2 ? (
                        <button
                          className={styles.watchVideoButton}
                          style={{
                            color: data.color === "white" ? "black" : "white",
                            }}
                          onClick={() => openVideo(data.videoUrl)}
                        >
                          <BsFillPlayBtnFill
                            className={styles.watchVideoIcon}
                          />
                        </button>
                      ) : (
                        index === 1 ? (
                          <span className={styles.warmupText}>
                            {section}
                          </span>
                        ) : (
                          section
                        )
                      )}
                    </div>
                  ))}
                </div>
                <div className={styles.textSection}>
                  {data.text2.split(" | ").map((section, index) => (
                    <div
                      key={index}
                      className={`${
                        index === 0
                          ? styles.text_subsection_1 // Apply text1-subsection-1 class here
                          : index === 1
                          ? styles.text_subsection_2 // Apply text1-subsection-2 class here
                          : index === 2
                          ? styles.text_subsection_3 // Apply text1-subsection-3 class here
                          : null // Add more conditions if needed
                      }`}
                    >
                      {section}
                    </div>
                  ))}
                </div>
                <div className={styles.textSection}>
                  {data.text3.split(" | ").map((section, index) => (
                    <div
                      key={index}
                      className={`${
                        index === 0
                          ? styles.text_subsection_1 // Apply text1-subsection-1 class here
                          : index === 1
                          ? styles.text_subsection_2 // Apply text1-subsection-2 class here
                          : index === 2
                          ? styles.text_subsection_3 // Apply text1-subsection-3 class here
                          : null // Add more conditions if needed
                      }`}
                    >
                      {section}
                    </div>
                  ))}
                </div>
                <div className={styles.textSection}>
                  {data.text4.split(" | ").map((section, index) => (
                    <div
                      key={index}
                      className={`${
                        index === 0
                          ? styles.text_subsection_1 // Apply text1-subsection-1 class here
                          : index === 1
                          ? styles.text_subsection_2 // Apply text1-subsection-2 class here
                          : index === 2
                          ? styles.text_subsection_3 // Apply text1-subsection-3 class here
                          : null // Add more conditions if needed
                      }`}
                    >
                      {section}
                    </div>
                  ))}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showVideo && (
        <div className={styles.videoPopup}>
          <div className={styles.videoCloseButton} onClick={closeVideo}>
            &times;
          </div>
          {videoUrl ? (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen="true"
            ></iframe>
          ) : (
            <>
              <div className={styles.comingSoonMessage}>Coming soon!</div>
              <img src={logo} alt="thenics4all Logo" className={styles.logo} />
              <div className={styles.comingSoonMessageSubtitle}>
                Working on recording the video!
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CardHSPUTrain;
