import "@aws-amplify/ui-react/styles.css";
import { React, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../css/Login.css";
import { Auth } from "aws-amplify"; // Import Auth from 'aws-amplify'
import { AiFillInfoCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { setAuthentication } from "./Store";

function Test({ isAuthenticated, setAuthentication }) {

  // Use an effect to check the authentication state when the component mounts
   useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setAuthentication(true); // User is authenticated
      })
      .catch(() => {
        setAuthentication(false); // User is not authenticated
      });
  }, [setAuthentication]);

  return (
    <div className="page">
      <Container fluid>
            <div className="signed-in">
              <h1>Welcome!</h1>
              <a className="home-link" href="/progress">
                Track Progress
              </a>
              <div>
              <button className="signed-in-button" >Sign Out</button>
              <button className="signed-in-button" >Delete Account</button>
              </div>
            </div>
        {!isAuthenticated ? (
          <div className="noteStyle">
            <p>
              <AiFillInfoCircle className="infoIcon" />
              <span>Sign in to delete the existing account.</span>
            </p>
          </div>
        ) : null}
      </Container>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.isAuthenticated,
  };
}

const mapDispatchToProps = {
  setAuthentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Test);
