import React, {useRef, useState, useEffect} from "react";
import styles from "../css/CardHSPUProgram.module.css";
import headerImage from "../assets/img/icon_hspu_white.png";
import html2canvas from "html2canvas";
import { FaDownload } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";
import data_program from '../data/data_program';
import logo from "../assets/img/t4a_logo_white.svg";

function CardHSPUProgram() {
  const cardRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");


  const handleCapture = () => {
    if (cardRef.current) {
      html2canvas(cardRef.current, { 
        scale: 4,
        backgroundColor: 'transparent' // Set the background to transparent
      }).then((canvas) => {
        // Convert the canvas to a data URL and create a download link
        const dataURL = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        a.href = dataURL;
        a.download = "thenics4all_hspu_program.png";
        a.click();
      });
    }
  };

   // Add an event listener to close the modal when clicking outside of it
   useEffect(() => {
    const handleOutsideClick = (event) => {
      if (showVideo && cardRef.current && !cardRef.current.contains(event.target)) {
        closeVideo();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showVideo]);

  const openVideo = (url) => {
    console.log("Opening video:", url); // Add this line for debugging
    setVideoUrl(url);
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl("");
  };


  const calculateRowspan = (data) => {
    const rowspanData = {};
    data.forEach((item) => {
      const { level } = item;
      if (!rowspanData[level]) {
        rowspanData[level] = 1;
      } else {
        rowspanData[level] += 1;
      }
    });
    return rowspanData;
  };

  const rowspanData = calculateRowspan(data_program);

  return (
    <div className={styles.card} ref={cardRef}>
      <div className={styles.cardHeader}>
        <img src={headerImage} alt="Header" className={styles.headerImage} />
        <div>
          <span>Handstand Pushup</span>
          <p className={styles.subtitle}>6 Levels - 18 Progressions</p>
        </div>
        <div className={styles.downloadButton} onClick={handleCapture}>
          <FaDownload />
        </div>
      </div>
      <table className={styles.cardTable}>
        <tbody>
        {/* HEADERS 
        <tr>
            <th>Level</th>
            <th>Time</th> 
            <th>Progression</th>
          </tr>*/}
          {data_program.map((data, index) => (
            <tr key={index} className={`${styles.level}-${index + 1}`}>
              {index % rowspanData[data.level] === 0 && (
                <td
                  rowSpan={rowspanData[data.level]}
                  className={`${styles.levelColumn} ${styles.levelCell}`}
                  style={{
                    backgroundColor: data.color,
                    color: data.color === "white" ? "black" : "white",
                  }}
                >
                  {data.level}
                </td>
              )}
               {/* AVERAGE TIME COLUMN
               <td className={`${styles.levelCell}`}
                style={{
                  background: data.color,
                  color: data.color === "white" ? "black" : "white",
                }}>{data.avgTime}</td> */}
              <td
                className={`${styles.levelCell} ${styles.progressionCell}`}
                style={{
                  background: data.color,
                  color: data.color === "white" ? "black" : "white",
                  textAlign: "left",
                }}
                onClick={() => openVideo(data.videoUrl)} // Open the video when clicked
              >
                <span>
                {data.progression}
                </span>
                <button
                  className={styles.watchVideoButton}
                  style={{
                    color: data.color === "white" ? "black" : "white",
                    float: "right",
                    }}
                >
                  <BsFillPlayBtnFill
                    className={styles.watchVideoIcon}
                  />
                </button>
              </td>{" "}
            </tr>
          ))}
        </tbody>
      </table>
      {showVideo && (
  <div className={styles.videoPopup}>
    <div className={styles.videoCloseButton} onClick={closeVideo}>
      &times;
    </div>
    {videoUrl ? (
      <iframe
        width="100%"
        height="315"
        src={videoUrl}
        title="YouTube Video"
        frameBorder="0"
        allowFullScreen="true"
      ></iframe>
    ) : (
      <>
        <div className={styles.comingSoonMessage}>
          Coming soon!
        </div>
        <img src={logo} alt="thenics4all Logo" className={styles.logo} />
        <div className={styles.comingSoonMessageSubtitle}>
          Working on recording the video!
        </div>
      </>
    )}
  </div>
)}
    </div>
  );
}

export default CardHSPUProgram;
