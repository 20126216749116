import React, { useRef, useState, useEffect } from "react";
import styles from "../css/CardHSPUProgress.module.css";
import headerImage from "../assets/img/icon_hspu_white.png";
import html2canvas from "html2canvas";
import { FaDownload } from "react-icons/fa";
import { BsFillPlayBtnFill } from "react-icons/bs";
import data_program from "../data/data_program";
import { FaLock } from "react-icons/fa";
import logo from "../assets/img/t4a_logo_white.svg";

function CardHSPUProgress({ data_user }) {
  const cardRef = useRef(null);
  const [showVideo, setShowVideo] = useState(false);
  const [videoUrl, setVideoUrl] = useState("");
  const [visibleUsers, setVisibleUsers] = useState(1); // Number of visible users

  const handleCapture = () => {
    if (cardRef.current) {
      html2canvas(cardRef.current, {
        scale: 4,
        backgroundColor: "transparent", // Set the background to transparent
      }).then((canvas) => {
        // Convert the canvas to a data URL and create a download link
        const dataURL = canvas.toDataURL("image/png");
        const a = document.createElement("a");
        a.href = dataURL;
        a.download = "thenics4all_hspu_progress.png";
        a.click();
      });
    }
  };

  // Add an event listener to close the modal when clicking outside of it
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (
        showVideo &&
        cardRef.current &&
        !cardRef.current.contains(event.target)
      ) {
        closeVideo();
      }
    };

    window.addEventListener("click", handleOutsideClick);

    return () => {
      window.removeEventListener("click", handleOutsideClick);
    };
  }, [showVideo]);

  const openVideo = (url) => {
    console.log("Opening video:", url); // Add this line for debugging
    setVideoUrl(url);
    setShowVideo(true);
  };

  const closeVideo = () => {
    setShowVideo(false);
    setVideoUrl("");
  };

  // Determine the number of users to display based on window width
  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 750) {
        setVisibleUsers(1); // Display 1 user for small screens
      } else if (windowWidth <= 1000) {
        setVisibleUsers(2); // Display 2 users for medium screens
      } else if (windowWidth <= 1250) {
        setVisibleUsers(3); // Display 2 users for medium screens
      } else if (windowWidth <= 1500) {
        setVisibleUsers(4); // Display 2 users for medium screens
      } else {
        setVisibleUsers(5); // Display 3 users for large screens
      }
    };

    // Initial call to set the number of users based on the current window width
    handleResize();

    // Add a resize event listener to update the number of users on window resize
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className={styles.card} ref={cardRef}>
      <div className={styles.cardHeader}>
        <img src={headerImage} alt="Header" className={styles.headerImage} />
        <div>
          <span>Handstand Pushup</span>
          <p className={styles.subtitle}>Progress</p>
        </div>
        <div className={styles.downloadButton} onClick={handleCapture}>
          <FaDownload />
        </div>
      </div>
      <table className={styles.cardTable}>
        <tbody>
          <tr>
            <th>Progression</th>
            {data_user.slice(0, visibleUsers).map((user) => (
              <th key={user.name}>{user.name}</th>
            ))}
          </tr>
          {data_program.map((data, dataIndex) => (
            <tr key={dataIndex} className={`${styles.level}-${dataIndex + 1}`}>
                            <td
                className={`${styles.levelCell} ${styles.progressionCell}`}
                style={{
                  background: data.color,
                  color: data.color === "white" ? "black" : "white",
                  textAlign: "left",
                }}
                onClick={() => openVideo(data.videoUrl)} // Open the video when clicked
              >
                <span>
                {data.progression}
                </span>
                <button
                  className={styles.watchVideoButton}
                  style={{
                    color: data.color === "white" ? "black" : "white",
                    float: "right",
                    }}
                >
                  <BsFillPlayBtnFill
                    className={styles.watchVideoIcon}
                  />
                </button>
              </td>{" "}
              {data_user.slice(0, visibleUsers).map((user, userIndex) => {
                const userProgress = user.progressData.find(
                  (item) => item.progress_code === data.progress_code
                );
                const progressPercentage = userProgress
                  ? (userProgress.progress / data.goal) * 100
                  : 0;

                return (
                  <td
                    key={userIndex}
                    className={`${styles.levelCell}`}
                    style={{
                      background: data.color,
                      color: data.color === "white" ? "black" : "white",
                      textAlign: "left",
                    }}
                  >
                    {userProgress && userProgress.progress > 0 ? (
                      <div className={styles.progressBar}>
                        <span
                          className={styles.progressPercentageLeft}
                          style={{
                            color: data.color === "white" ? "black" : "white",
                          }}
                        >
                          {userProgress.progress}
                        </span>
                        <div
                          className={styles.progressFill}
                          style={{
                            width: `${progressPercentage}%`,
                            backgroundColor:
                              progressPercentage < 25
                                ? "red"
                                : progressPercentage >= 25 &&
                                  progressPercentage <= 75
                                ? "yellow"
                                : "green",
                          }}
                        ></div>
                        <span
                          className={styles.progressPercentageRight}
                          style={{
                            color: data.color === "white" ? "black" : "white",
                          }}
                        >
                          {`${progressPercentage.toFixed(0)}%`}
                        </span>
                      </div>
                    ) : (
                      // Render a padlock icon if progress is 0
                      <div
                        className={styles.lockedIcon}
                        style={{
                          color: data.color === "white" ? "black" : "white",
                        }}
                      >
                        <FaLock />
                      </div>
                    )}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
      {showVideo && (
        <div className={styles.videoPopup}>
          <div className={styles.videoCloseButton} onClick={closeVideo}>
            &times;
          </div>
          {videoUrl ? (
            <iframe
              width="100%"
              height="315"
              src={videoUrl}
              title="YouTube Video"
              frameBorder="0"
              allowFullScreen="true"
            ></iframe>
          ) : (
            <>
              <div className={styles.comingSoonMessage}>Coming soon!</div>
              <img src={logo} alt="thenics4all Logo" className={styles.logo} />
              <div className={styles.comingSoonMessageSubtitle}>
                Working on recording the video!
              </div>
            </>
          )}
        </div>
      )}
    </div>
  );
}

export default CardHSPUProgress;
