import { Authenticator } from "@aws-amplify/ui-react";
import "@aws-amplify/ui-react/styles.css";
import { React, useEffect, useState } from "react";
import { Container, Modal, Button } from "react-bootstrap";
import "../css/Login.css";
import "../css/ComingSoon.css";
import { Auth } from "aws-amplify";
import { AiFillInfoCircle } from "react-icons/ai";
import { connect } from "react-redux";
import { setAuthentication } from "./Store";

function Login({ isAuthenticated, setAuthentication }) {
  const [showModal, setShowModal] = useState(false);
  const [modalMessage, setModalMessage] = useState("");
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSignOutModal, setShowSignOutModal] = useState(false);

  const handleSignOut = async (signOutFunction) => {
    setShowSignOutModal(false);
    await signOutFunction();
    setModalMessage("Successfully signed out.");
    setShowModal(true);
  };

  const confirmSignOut = () => {
    setShowSignOutModal(true);
  };

  const handleDeleteAccount = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      await Auth.deleteUser(user);
      setModalMessage("Account deleted successfully.");
      setShowModal(true);
      await Auth.signOut();
    } catch (error) {
      console.error("Error deleting account:", error);
    }
  };

  const confirmDeleteAccount = () => {
    setShowConfirmModal(true);
  };

  const handleConfirmDelete = async () => {
    setShowConfirmModal(false);
    await handleDeleteAccount();
  };

  useEffect(() => {
    Auth.currentAuthenticatedUser()
      .then(() => {
        setAuthentication(true);
      })
      .catch(() => {
        setAuthentication(false);
      });
  }, [setAuthentication]);

  return (
    <div className="page">
      <Container fluid>
        <Authenticator>
          {({ signOut }) => (
            <div className="signed-in">
              <h1>Welcome!</h1>
              <a className="home-link" href="/progress">
                Track Progress
              </a>
              <div>
                <Button
                  className="signed-in-button"
                  onClick={confirmSignOut}
                >
                  Sign Out
                </Button>
                <Button
                  className="signed-in-button"
                  onClick={confirmDeleteAccount}
                >
                  Delete Account
                </Button>
              </div>

              {/* Sign Out Confirmation Modal */}
              <Modal show={showSignOutModal} onHide={() => setShowSignOutModal(false)} centered>
                <Modal.Header closeButton>
                  <Modal.Title>Confirm Sign Out</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  Are you sure you want to sign out?
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={() => setShowSignOutModal(false)}>
                    No, Keep me Signed-in
                  </Button>
                  <Button variant="danger" onClick={() => handleSignOut(signOut)}>
                    Yes, Sign me out
                  </Button>
                </Modal.Footer>
              </Modal>
            </div>
          )}
        </Authenticator>
        {!isAuthenticated ? (
          <div className="noteStyle">
            <p>
              <AiFillInfoCircle className="infoIcon" />
              <span>Sign in to delete the existing account.</span>
            </p>
          </div>
        ) : null}
      </Container>

      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Notification</Modal.Title>
        </Modal.Header>
        <Modal.Body>{modalMessage}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showConfirmModal} onHide={() => setShowConfirmModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Delete</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete your account? All your progress will be lost.
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowConfirmModal(false)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            Delete Account
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

function mapStateToProps(state) {
  return {
    isAuthenticated: state.isAuthenticated,
  };
}

const mapDispatchToProps = {
  setAuthentication,
};

export default connect(mapStateToProps, mapDispatchToProps)(Login);
