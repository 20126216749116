import { React, useState, useEffect } from "react";
import { Container } from "react-bootstrap";
import "../css/Home.css";
import "../css/Disclaimer.css";
import { Helmet } from "react-helmet";
import DisclaimerPrivacy from "./DisclaimerPrivacy";
import DisclaimerExercise from "./DisclaimerExercise";
import DisclaimerContent from "./DisclaimerContent";

function Disclaimer() {
  const [displayText, setDisplayText] = useState(null);
  const [selectedTab, setSelectedTab] = useState("privacy");

  useEffect(() => {
    setDisplayText(<DisclaimerPrivacy />);
  }, []);

  const handlePrivacyPolicyClick = () => {
    setDisplayText(<DisclaimerPrivacy />);
    setSelectedTab("privacy");
  };

  const handleDisclaimerClick = () => {
    setDisplayText(<DisclaimerExercise />);
    setSelectedTab("disclaimer");
  };

  const handleContentClick = () => {
    setDisplayText(<DisclaimerContent />);
    setSelectedTab("content");
  };

  return (
    <div className="page">
      <Helmet>
        <title>thenics4all</title>
        <meta
          name="description"
          content="Step-by-step Handstand pushup progressions, training and tutorials."
        />
        <meta
          name="keywords"
          content="handstand, handstand pushup, hspu, calisthenics, progression, tutorial"
        />
      </Helmet>
      <Container fluid>
        <div className="home-row row-long">
          <div className="fixed-header">
            <h1>
              <div className="button-container">
                <a
                  className={`home-link disclaimer-link ${
                    selectedTab === "privacy" ? "selected" : "unselected"
                  }`}
                  href="/disclaimer"
                  onClick={(e) => {
                    e.preventDefault();
                    handlePrivacyPolicyClick();
                  }}
                >
                  Privacy Policy
                </a>
                <a
                  className={`home-link disclaimer-link ${
                    selectedTab === "disclaimer" ? "selected" : "unselected"
                  }`}
                  href="/disclaimer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDisclaimerClick();
                  }}
                >
                  Fitness and Exercise Disclaimer
                </a>
                <a
                  className={`home-link disclaimer-link ${
                    selectedTab === "content" ? "selected" : "unselected"
                  }`}
                  href="/disclaimer"
                  onClick={(e) => {
                    e.preventDefault();
                    handleContentClick();
                  }}
                >
                  User Content Disclaimer
                </a>
              </div>
              <hr className="disclaimer-horizontal-rule" />
            </h1>
          </div>
          <div className="home-about left">{displayText}</div>
          <div>
            <br></br>
            <br></br>
          </div>
        </div>
      </Container>
    </div>
  );
}
export default Disclaimer;
